import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { FastField, FormikProps } from 'formik';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/* Components */
import AutoComplete from './AutoComplete';
import { RadioButton, RadioButtonGroup } from '../../../common/RadioButton';
import SaveButtons from '../../Dialog/EventCreateDialog/SaveButtons';
import FooterButtons from '../../Dialog/EventCreateDialog/FooterButtons';
import AddCustomerForm from '../AddCustomerForm/AddCustomerForm';
import TimeMask from './TimeMask';

/* Mutations & Queries */
import AddressAutoCompleteAddressMutation from '../../../mutations/Event/AddressFormAddressAutoCompleteMutation';
import AddressFormGooglePlacesZipMutation from '../../../mutations/Event/AddressFormGooglePlacesZipMutation';
import GET_CUSTOMERS_BY_SEARCH_QUERY from '../../../queries/CustomersBySearch';

/* Types */
import { MyFormValues } from '../../Event/TabContent/Details/FormTypes';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';

/* Helpers & Functions */
import useToggle from '../../../utils/useToggleHook';
import { allowedStates, statesAbbrev } from '../../../utils/statesWeCanShipTo';
import { formatAddress } from '../../Event/TabContent/Charity/helpers';
import { focusErrors } from './submitHelpers';
import { timeZones } from './timeZones';

/* Styles */
import { styles } from '@onehope/design-system';
import {
  MuiInput,
  MuiButton,
  MuiDatePicker,
  MuiInputAutosize,
  MuiToolTip,
} from '@onehope/design-system-ohw';
import { Grid } from '@onehope/design-system-v2';
import MenuItem from '@material-ui/core/MenuItem';
import DialogRoot from '@material-ui/core/Dialog';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { CardInfo, CardLine, CardTitle } from '../AddCustomerForm/index.styles';
import { ToggleLabel } from '../../Event/TabContent/index.styles';
import {
  SelectInput,
  DefaultOption,
  ArrowDropDown,
  Label,
  MiniSelect,
  MenuProps,
  Col,
  Row,
  SelectContainer,
} from './index.styles';
import {
  CheckBoxIcon,
  StyledTypography,
} from '../../Contact/TabContent/Details/Form';
import EditImage from './EditImage';

const { styled: s, cssConstants } = styles;

export interface Address {
  addressId: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  country: string;
  id: string;
  placeId: string;
  state: string;
  zip?: string;
}

interface CustomerData {
  fullName: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  email: string;
  phone: string;
  accountId: string;
}

interface EventDetailsFormProps {
  handlePreviousStepChange: any;
  editing: boolean;
  event: EventType;
}

type FormProps = {
  setIsNotEditing: () => void;
  mutationLoading: boolean;
};

interface CustomerNode {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  organizationName?: string;
  email: string;
  mobilePhone: string;
  accountId: string;
}

interface Customer {
  node: CustomerNode;
}

const HorizontalLine = styled.div`
  width: 176px;
  height: 1px;
  background-color: #dddddd;
`;

const OrContainer = styled.div`
  height: 26px;
  max-width: 432px;
  width: 100%;
  margin: 0 auto 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const OrText = styled.div`
  width: 32px;
  height: 26px;
  /* font-family: ProximaNova; */
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 3px;
  text-align: center;
  color: #000000;
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

const NewCustomerButton = styled.div`
  max-width: 432px;
  height: 100px;
  color: #1a1a1a;
  cursor: pointer;
`;

const NewCustomerDialog = styled(DialogRoot)`
  &.MuiDialog-root {
    /* z-index: 9000 !important; */
  }
`;

const NewCustomerText = styled.div`
  /* font-family: ProximaNova; */
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 8px;
`;

const CustomerTextContainer = styled.div`
  max-width: 432px;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CustomerCard = styled.div`
  max-height: 200px;
  border-radius: 4px;
  border: solid 1px ${cssConstants.primaryColor};
  background-color: ${cssConstants.neutralBackgroundColor};
`;

export const UpdateButton = styled.div`
  margin-top: 10px;
  width: 250px;
  height: 18px;
  /* font-family: ProximaNova; */
  font-family: ${cssConstants.font};
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: ${cssConstants.primaryColor};
  text-decoration: underline;
  cursor: pointer;
`;

const InputContainer = styled.div`
  padding-top: 24px;
`;

const LogisticsContainer = styled.div`
  width: 180px;
`;

const EventTitleContainer = styled.div`
  margin: 24px 0 -44px 0;
`;

const DatePicker = styled(MuiDatePicker)`
  && {
    width: 192px;
    margin-bottom: 25px;
    @media all and (min-width: 768px) {
      width: 288px;
    }
  }
`;

const ShortInput = styled(MuiInput)`
  && {
    width: 106px;
    margin-bottom: 25px;
    @media all and (min-width: 768px) {
      width: 216px;
    }
  }
`;

const LongInput = styled(MuiInput)`
  && {
    max-width: 432px;
    margin-bottom: 25px;
  }
`;

export const MedInput = styled(MuiInput)`
  && {
    display: flex;
    flex-direction: column;
    width: 244px;
    margin-bottom: 25px;
  }
`;

export const SearchLink = styled.div`
  max-width: 432px;
  height: 18px;
  /* font-family: ProximaNova; */
  font-family: ${cssConstants.font};
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: ${cssConstants.primaryColor};
  text-decoration: underline;
  margin-top: -15px;
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;
  cursor: pointer;
`;

const OptionLabel = styled.div`
  padding-left: 16px;
`;

const formatDisplayDate = (date: DateTime) => {
  if (!date) return '';
  if (!date.c) return date;
  const {
    c: { month, day, year },
  } = date;
  const formattedDate = `${month}/${day.toString().padStart(2, 0)}/${year}`;
  return formattedDate.padStart(10, 0);
};

export const formatPhone = (value: string) => {
  if (!value) return null;
  let i = 0;
  const number = value.replace(/[- )(+]/g, '');
  const pattern = number.length === 10 ? '(###) ###-####' : '# (###) ###-####';
  return pattern.replace(/#/g, _ => number[i++]);
};

const formatCustomers = (data: any) => {
  return (
    get(data, 'viewer.user.customers.edges') &&
    get(data, 'viewer.user.customers.edges').map((customer: Customer) =>
      customer && customer.node
        ? {
            organizationName: customer.node.organizationName || '',
            fullName:
              customer.node.firstName && customer.node.lastName
                ? `${customer.node.firstName} ${customer.node.lastName}`
                : '',
            firstName: customer.node.firstName || '',
            lastName: customer.node.lastName || '',
            email: customer.node.email,
            phone: customer.node.mobilePhone,
            accountId: customer.node.accountId,
          }
        : '',
    )
  );
};

export const getManualAddress = (
  editing: boolean,
  type: string,
  eventId: string,
) => {
  if (editing && localStorage.getItem(`${type}${eventId}`)) {
    return localStorage.getItem(`${type}${eventId}`) === 'true';
  } else {
    if (localStorage.getItem(`${type}`)) {
      return localStorage.getItem(`${type}`) === 'true';
    }
  }
  return false;
};

export default function EventDetailsForm(
  props: FormProps & FormikProps<MyFormValues> & EventDetailsFormProps,
) {
  const {
    values,
    errors,
    touched,
    event,
    handleChange,
    handleReset,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    setIsNotEditing,
    mutationLoading,
    handlePreviousStepChange,
    editing,
  } = props;
  const {
    trinityPartyType,
    hostFullName,
    hostNonProfitName,
    hostEmail,
    hostPhone,
    hostAccountId,
    eventDate,
    hostType,
    hostCustomerType,
    displayAddress,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
    eventTime,
    eventTimeLocale,
    timeZone,
    title,
    showSupporters,
  } = values;
  const { value: open, setTrue: setOpen, setFalse: setClosed } = useToggle();

  // avoid using for loop for useEffect since hooks have to render in same order (throwing errors)
  useEffect(() => {
    localStorage.setItem(
      'trinityPartyType',
      trinityPartyType ? trinityPartyType : '',
    );
    setFieldTouched('trinityPartyType', true, false);
  }, [trinityPartyType, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem('hostFullName', hostFullName ? hostFullName : '');
  }, [hostFullName]);

  useEffect(() => {
    localStorage.setItem('hostAccountId', hostAccountId ? hostAccountId : '');
  }, [hostAccountId]);

  useEffect(() => {
    localStorage.setItem('hostEmail', hostEmail ? hostEmail : '');
  }, [hostEmail]);

  useEffect(() => {
    localStorage.setItem('hostPhone', hostPhone ? hostPhone : '');
  }, [hostPhone]);

  useEffect(() => {
    localStorage.setItem('eventDate', eventDate ? eventDate : '');
  }, [eventDate]);

  useEffect(() => {
    localStorage.setItem('hostType', hostType ? hostType : '');
    setFieldTouched('hostType', true, false);
  }, [hostType, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem(
      'hostCustomerType',
      hostCustomerType ? hostCustomerType : '',
    );
  }, [hostCustomerType]);

  useEffect(() => {
    localStorage.setItem(
      'displayAddress',
      displayAddress ? displayAddress : '',
    );
  }, [displayAddress]);

  useEffect(() => {
    localStorage.setItem(
      'addressLineOne',
      addressLineOne ? addressLineOne : '',
    );
  }, [addressLineOne]);

  useEffect(() => {
    localStorage.setItem(
      'addressLineTwo',
      addressLineTwo ? addressLineTwo : '',
    );
  }, [addressLineTwo]);

  useEffect(() => {
    localStorage.setItem('city', city ? city : '');
  }, [city]);

  useEffect(() => {
    localStorage.setItem('state', state ? state : '');
  }, [state]);

  useEffect(() => {
    localStorage.setItem('zip', zip ? zip : '');
  }, [zip]);

  useEffect(() => {
    localStorage.setItem('eventTime', eventTime ? eventTime : '');
  }, [eventTime]);

  useEffect(() => {
    localStorage.setItem(
      'eventTimeLocale',
      eventTimeLocale ? eventTimeLocale : '',
    );
  }, [eventTimeLocale]);

  useEffect(() => {
    localStorage.setItem(
      'hostNonProfitName',
      hostNonProfitName ? hostNonProfitName : '',
    );
  }, [hostNonProfitName]);

  useEffect(() => {
    localStorage.setItem('timeZone', timeZone ? timeZone : '');
  }, [timeZone]);

  useEffect(() => {
    localStorage.setItem(
      'showSupporters',
      showSupporters ? showSupporters : '',
    );
  }, [showSupporters]);

  /* LAZY QUERIES */

  // /*  QUERIES */
  const { refetch: getCustomers } = useQuery(GET_CUSTOMERS_BY_SEARCH_QUERY, {
    variables: { search: '' },
    skip: true,
  });

  /* MUTATIONS */
  const [
    addressAutoComplete,
    { loading: mutationAddressAutoCompleteLoading },
  ] = useMutation(AddressAutoCompleteAddressMutation);

  const [
    googlePlacesZip,
    { loading: mutationGooglePlacesZipLoading },
  ] = useMutation(AddressFormGooglePlacesZipMutation);

  /* STATE */
  const [isAddressMenuOpen, setIsAddressMenuOpen] = useState(false);
  const [isHostNameMenuOpen, setIsHostNameMenuOpen] = useState(false);
  const [isCustomerSelected, setIsCustomerSelected] = useState(
    hostFullName !== '',
  );
  const [addressPredictions, setAddressPredictions] = useState({});
  const [manualAddress, setManualAddress] = useState(
    getManualAddress(editing, 'manualAddress', event?.eventId),
  );

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    editing
      ? localStorage.setItem(
          `manualAddress${event.eventId}`,
          manualAddress ? 'true' : 'false',
        )
      : localStorage.setItem(`manualAddress`, manualAddress ? 'true' : 'false');
  }, [manualAddress]);

  useEffect(() => {
    focusErrors({ errors, isSubmitting, manualAddress });
  }, [errors, isSubmitting, manualAddress]);

  /* FUNCTIONS */
  const change = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleSelectChange = (field: string, selectedItem: string) => {
    closeMenu(field);
    handleSetValue(field, selectedItem);
  };

  const handleSetValue = (field: string, selectedItem: string) => {
    setFieldValue(field, selectedItem);
    setFieldTouched(field, true, false);
  };

  const textChangeValid = (event: React.SyntheticEvent) => {
    return event.target.value.length <= 75;
  };

  const handleCustomerSelected = (selectedItem: CustomerData) => {
    const {
      firstName,
      lastName,
      fullName,
      organizationName,
      email,
      phone,
      accountId,
    } = selectedItem;
    handleSelectChange('hostFullName', organizationName || fullName);
    handleSelectChange('hostFirstName', firstName);
    handleSelectChange('hostLastName', lastName);
    handleSelectChange('hostEmail', email);
    handleSelectChange('hostPhone', phone);
    handleSelectChange('hostAccountId', accountId);
    handleSetValue('hostCustomerType', 'Existing Customer');
    setIsCustomerSelected(true);
  };

  const handleAddressSelected = async (selectedItem: any) => {
    const { addressLineOne, city, state, zip, placeId } = selectedItem;
    setFieldValue('addressLineOne', addressLineOne);
    setFieldValue('city', city);
    setFieldValue('state', state);
    setFieldValue('zip', zip);
    if (!zip) {
      const variables = {
        input: {
          placeId: placeId,
        },
      };
      return googlePlacesZip({ variables }).then(
        ({
          data: {
            googlePlacesZip: { zip },
          },
        }) => {
          setFieldValue('zip', zip || '');
          handleSelectChange(
            'displayAddress',
            formatAddress({ addressLineOne, city, state, zip }),
          );
        },
      );
    }
  };

  const handleOuterClick = (field: string) => {
    setFieldValue(field, '');
    closeMenu(field);
  };

  const closeMenu = (field: string) => {
    switch (field) {
      case 'displayAddress': {
        setIsAddressMenuOpen(false);
        break;
      }
      case 'hostFullName': {
        setIsHostNameMenuOpen(false);
        break;
      }
    }
  };

  const handleHostNameChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    change('hostFullName', event);
    const search = event.target.value;
    if (search && search !== '') {
      setIsHostNameMenuOpen(true);
      await getCustomers({ search }).then(results => {
        if (get(results, 'data.viewer')) {
          setCustomers(formatCustomers(results.data));
        }
      });
    } else {
      setIsHostNameMenuOpen(false);
      setCustomers([]);
    }
  };

  const handleAddressChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    change('displayAddress', event);
    const searchTerm = event.target.value;
    if (!searchTerm) {
      handleSetValue('addressLineOne', '');
      handleSetValue('city', '');
      handleSetValue('state', '');
      handleSetValue('zip', '');
    }
    const variables = {
      input: {
        query: searchTerm,
      },
    };
    await addressAutoComplete({
      variables,
    }).then((res: any) => {
      const { data } = res;
      setIsAddressMenuOpen(true);
      const predictions =
        data &&
        data.addressAutoComplete &&
        data.addressAutoComplete.addressPredictions;
      let allowedAddresses;
      if (predictions) {
        allowedAddresses = predictions.filter((address: Address) => {
          return (
            allowedStates.includes(address.state) &&
            /\d/.test(address.addressLineOne)
          );
        });
      }
      const listData = allowedAddresses || [];
      const truthyList = listData.filter((address: Address) => {
        return !!address;
      }) as Address[];
      setAddressPredictions(truthyList);
    });
  };

  const handleSelectedCustomer = (values: any) => {
    handleNewCustomerClose();
    const { firstName, lastName, fullName, email, phone, accountId } = values;
    setIsCustomerSelected(true);
    handleSetValue('hostFullName', fullName);
    handleSetValue('hostFirstName', firstName);
    handleSetValue('hostLastName', lastName);
    handleSetValue('hostEmail', email);
    handleSetValue('hostPhone', phone);
    handleSetValue('hostAccountId', accountId);
    handleSetValue('hostCustomerType', values?.hostCustomerType || '');
  };

  const handleHostReset = () => {
    setIsCustomerSelected(false);
    handleSetValue('hostFullName', '');
    handleSetValue('hostFirstName', '');
    handleSetValue('hostLastName', '');
    handleSetValue('hostEmail', '');
    handleSetValue('hostPhone', '');
    handleSetValue('hostAccountId', '');
    handleSetValue('hostCustomerType', '');
  };

  const onCancel = () => {
    setManualAddress(true);
    handleReset();
    return setIsNotEditing();
  };

  function handleNewCustomerClose() {
    setClosed();
  }

  function handleNewCustomerOpen() {
    setOpen();
  }

  function toggleAddManualAddress() {
    handleSelectChange(
      'addressLineOne',
      addressLineOne || displayAddress || '',
    );
    handleSelectChange('displayAddress', '');
    setManualAddress(!manualAddress);
  }

  const toggleCheckbox = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    setFieldValue(name, e.target.checked?.toString());
    setFieldTouched(name, true, false);
  };

  const disableNext = hostType === '';
  const isInPerson = trinityPartyType === 'EVENT';
  const isCustomer = hostType === 'CUSTOMER';
  const isNonProfit = hostType === 'NONPROFIT';
  const isNotSelfHost = isCustomer || isNonProfit;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {!editing && (
          <s.LoginHeader css>
            Event details{' '}
            <span role="img" aria-labelledby="pencil-and-paper">
              📝
            </span>
          </s.LoginHeader>
        )}
        <ToggleLabel>Where is the event located?</ToggleLabel>
        <RadioButtonGroup
          id="trinityPartyTypeGroup"
          label=""
          value={trinityPartyType}
          error={errors.trinityPartyType}
          touched={touched.trinityPartyType}
        >
          <FastField
            component={RadioButton}
            name="trinityPartyType"
            id="EVENT"
            label="In-person"
          />
          <FastField
            component={RadioButton}
            name="trinityPartyType"
            id="EPARTY"
            label="Online"
          />
        </RadioButtonGroup>
        {!!trinityPartyType && (
          <div>
            <ToggleLabel>Who is hosting?</ToggleLabel>
            <RadioButtonGroup
              id="hostTypeGroup"
              label=""
              value={hostType}
              error={errors.hostType}
              touched={touched.hostType}
            >
              <FastField
                component={RadioButton}
                name="hostType"
                id="CUSTOMER"
                label="A contact"
              />
              <FastField
                component={RadioButton}
                name="hostType"
                id="NONPROFIT"
                label="A nonprofit"
              />
              <FastField
                component={RadioButton}
                name="hostType"
                id="SELFHOST"
                label="I am hosting"
              />
            </RadioButtonGroup>
          </div>
        )}
        {!!isNonProfit && (
          <InputContainer>
            <LongInput
              label="Nonprofit name"
              placeholder="Enter 501(c)3 name"
              id="hostNonProfitName"
              name="hostNonProfitName"
              value={hostNonProfitName}
              fullWidth
              hasError={
                !isSubmitting &&
                touched.hostNonProfitName &&
                Boolean(errors.hostNonProfitName)
              }
              error={
                touched.hostNonProfitName && Boolean(errors.hostNonProfitName)
              }
              helperText={
                errors.hostNonProfitName &&
                touched.hostNonProfitName &&
                errors.hostNonProfitName
              }
              hasSuccess={
                editing
                  ? !isSubmitting &&
                    ((isEmpty(touched) && isEmpty(errors)) ||
                      ((touched.hostNonProfitName ||
                        !Boolean(touched.hostNonProfitName)) &&
                        !Boolean(errors.hostNonProfitName)))
                  : !isSubmitting &&
                    touched.hostNonProfitName &&
                    !Boolean(errors.hostNonProfitName)
              }
              onChange={(e: React.SyntheticEvent) =>
                change('addressLineOne', e)
              }
            />
          </InputContainer>
        )}
        {!!isNotSelfHost && (
          <InputContainer>
            {!isCustomerSelected ? (
              <div>
                <AutoComplete
                  id="hostFullNameDropDown"
                  inputId="hostFullName"
                  label="Contact's name"
                  placeholder="Search existing contacts"
                  value={values.hostFullName}
                  error={errors.hostFullName}
                  touched={touched.hostFullName}
                  isSubmitting={isSubmitting}
                  isMenuOpen={isHostNameMenuOpen}
                  handleCustomerSelected={handleCustomerSelected}
                  handleInputChange={handleHostNameChange}
                  handleOuterClick={handleOuterClick}
                  objectPredictions={customers}
                  handleNewCustomerOpen={handleNewCustomerOpen}
                  editing={editing}
                />
                <OrContainer>
                  <HorizontalLine />
                  <OrText>OR</OrText>
                  <HorizontalLine />
                </OrContainer>
                <NewCustomerButton>
                  <MuiButton
                    type="DASHED"
                    fullWidth
                    disableRipple
                    onClick={handleNewCustomerOpen}
                  >
                    <CustomerTextContainer>
                      <AddCircleOutline />
                      <NewCustomerText>add new contact</NewCustomerText>
                    </CustomerTextContainer>
                  </MuiButton>
                </NewCustomerButton>
                <NewCustomerDialog
                  fullScreen={fullScreen}
                  disableEnforceFocus
                  open={open}
                  onClose={handleNewCustomerClose}
                >
                  <AddCustomerForm
                    formTitle="New Contact"
                    addCustomerButtonText="add new contact"
                    existingCustomerButtonText={
                      'continue with existing contact'
                    }
                    onClose={handleNewCustomerClose}
                    handleSelectedCustomer={handleSelectedCustomer}
                    defaultValues={{
                      firstName: hostFullName || '',
                      lastName: '',
                      email: hostEmail || '',
                      phone: hostPhone || '',
                    }}
                  />
                </NewCustomerDialog>
              </div>
            ) : (
              <div>
                {/* TODO (monica): make this card a reusable component, similar one also used in AddCustomer */}
                <CustomerCard>
                  <CardInfo>
                    <CardTitle>Contact Details</CardTitle>
                    {hostFullName && <CardLine>{hostFullName}</CardLine>}
                    {hostEmail && <CardLine>{hostEmail}</CardLine>}
                    {hostPhone && hostPhone !== 'null' && (
                      <CardLine>{formatPhone(hostPhone)}</CardLine>
                    )}
                    <UpdateButton onClick={handleHostReset}>Clear</UpdateButton>
                  </CardInfo>
                </CustomerCard>
              </div>
            )}
          </InputContainer>
        )}
        {!!hostType && (
          <div>
            <EventTitleContainer>
              <MuiInputAutosize
                label="Event Title"
                rows={1}
                rowsMax={4}
                maxCount="75"
                toolTipText="This title will be used on the fundraiser’s landing page"
                placeholder="E.g. Jane’s Wine Tasting Fundraiser"
                value={title}
                name="title"
                id="title"
                validInput={title.length > 0}
                onChange={(e: React.SyntheticEvent) => {
                  if (textChangeValid(e)) {
                    change('title', e);
                  }
                }}
              />
            </EventTitleContainer>
            {isInPerson && (
              <div>
                {manualAddress ? (
                  <InputContainer>
                    <LongInput
                      label="Address 1"
                      placeholder="Enter address"
                      id="addressLineOne"
                      name="addressLineOne"
                      value={addressLineOne}
                      fullWidth
                      hasError={
                        !isSubmitting &&
                        touched.addressLineOne &&
                        Boolean(errors.addressLineOne)
                      }
                      error={
                        touched.addressLineOne && Boolean(errors.addressLineOne)
                      }
                      helperText={
                        errors.addressLineOne &&
                        touched.addressLineOne &&
                        errors.addressLineOne
                      }
                      hasSuccess={
                        editing
                          ? !isSubmitting &&
                            ((isEmpty(touched) && isEmpty(errors)) ||
                              ((touched.addressLineOne ||
                                !Boolean(touched.addressLineOne)) &&
                                !Boolean(errors.addressLineOne)))
                          : !isSubmitting &&
                            touched.addressLineOne &&
                            !Boolean(errors.addressLineOne)
                      }
                      onChange={(e: React.SyntheticEvent) =>
                        change('addressLineOne', e)
                      }
                    />
                    <SearchLink onClick={toggleAddManualAddress}>
                      Back to address search
                    </SearchLink>
                    <LongInput
                      label="Address 2"
                      placeholder="Apt, Bldg, Ste #"
                      id="addressLineTwo"
                      name="addressLineTwo"
                      value={addressLineTwo}
                      fullWidth
                      hasError={
                        !isSubmitting &&
                        touched.addressLineTwo &&
                        Boolean(errors.addressLineTwo)
                      }
                      error={
                        touched.addressLineTwo && Boolean(errors.addressLineTwo)
                      }
                      helperText={
                        errors.addressLineTwo &&
                        touched.addressLineTwo &&
                        errors.addressLineTwo
                      }
                      hasSuccess={
                        editing
                          ? !isSubmitting &&
                            ((isEmpty(touched) && isEmpty(errors)) ||
                              ((touched.addressLineTwo ||
                                !Boolean(touched.addressLineTwo)) &&
                                !Boolean(errors.addressLineTwo)))
                          : !isSubmitting &&
                            touched.addressLineTwo &&
                            !Boolean(errors.addressLineTwo)
                      }
                      onChange={(e: React.SyntheticEvent) =>
                        change('addressLineTwo', e)
                      }
                    />
                    <LongInput
                      label="City"
                      placeholder="Manhattan Beach"
                      id="city"
                      name="city"
                      value={city}
                      fullWidth
                      hasError={
                        !isSubmitting && touched.city && Boolean(errors.city)
                      }
                      error={touched.city && Boolean(errors.city)}
                      helperText={errors.city && touched.city && errors.city}
                      hasSuccess={
                        editing
                          ? !isSubmitting &&
                            ((isEmpty(touched) && isEmpty(errors)) ||
                              ((touched.city || !Boolean(touched.city)) &&
                                !Boolean(errors.city)))
                          : !isSubmitting &&
                            touched.city &&
                            !Boolean(errors.city)
                      }
                      onChange={(e: React.SyntheticEvent) => change('city', e)}
                    />
                    <Row>
                      <Col>
                        <MiniSelect
                          IconComponent={ArrowDropDown}
                          input={
                            <SelectInput
                              label="State"
                              id="state"
                              width="164"
                              hasError={touched.state && Boolean(errors.state)}
                            />
                          }
                          value={state}
                          displayEmpty
                          onChange={(e: React.SyntheticEvent) =>
                            change('state', e)
                          }
                          error={touched.state && Boolean(errors.state)}
                          helperText={
                            errors.state && touched.state && errors.state
                          }
                          inputProps={{
                            name: 'state',
                            id: 'state',
                            MenuProps: MenuProps,
                          }}
                        >
                          >
                          <MenuItem component="li" value="">
                            <DefaultOption>Select one</DefaultOption>
                          </MenuItem>
                          {statesAbbrev.map(state => (
                            <MenuItem
                              button={true}
                              component="li"
                              key={state}
                              value={state}
                            >
                              <OptionLabel>{state}</OptionLabel>
                            </MenuItem>
                          ))}
                        </MiniSelect>
                      </Col>
                      <Col>
                        <MedInput
                          label="Zipcode"
                          placeholder="90210"
                          id="zip"
                          name="zip"
                          value={zip}
                          fullWidth
                          hasError={
                            !isSubmitting && touched.zip && Boolean(errors.zip)
                          }
                          error={touched.zip && Boolean(errors.zip)}
                          helperText={errors.zip && touched.zip && errors.zip}
                          hasSuccess={
                            editing
                              ? !isSubmitting &&
                                ((isEmpty(touched) && isEmpty(errors)) ||
                                  ((touched.zip || !Boolean(touched.zip)) &&
                                    !Boolean(errors.zip)))
                              : !isSubmitting &&
                                touched.zip &&
                                !Boolean(errors.zip)
                          }
                          onChange={(e: React.SyntheticEvent) =>
                            change('zip', e)
                          }
                        />
                      </Col>
                    </Row>
                  </InputContainer>
                ) : (
                  <InputContainer>
                    <AutoComplete
                      id="displayAddressDropDown"
                      inputId="displayAddress"
                      label="Location"
                      placeholder="Start typing to search"
                      value={values.displayAddress}
                      error={errors.addressLineOne ? 'Required' : null}
                      touched={touched.displayAddress}
                      isSubmitting={isSubmitting}
                      isMenuOpen={isAddressMenuOpen}
                      handleAddressSelected={handleAddressSelected}
                      toggleAddManualAddress={toggleAddManualAddress}
                      handleInputChange={handleAddressChange}
                      handleOuterClick={handleOuterClick}
                      objectPredictions={addressPredictions}
                      editing={editing}
                    />
                    <LongInput
                      label="Address 2"
                      placeholder="Apt, Bldg, Ste #"
                      id="addressLineTwo"
                      name="addressLineTwo"
                      value={addressLineTwo}
                      fullWidth
                      hasError={
                        !isSubmitting &&
                        touched.addressLineTwo &&
                        Boolean(errors.addressLineTwo)
                      }
                      error={
                        touched.addressLineTwo && Boolean(errors.addressLineTwo)
                      }
                      helperText={
                        errors.addressLineTwo &&
                        touched.addressLineTwo &&
                        errors.addressLineTwo
                      }
                      hasSuccess={
                        editing
                          ? !isSubmitting &&
                            ((isEmpty(touched) && isEmpty(errors)) ||
                              ((touched.addressLineTwo ||
                                !Boolean(touched.addressLineTwo)) &&
                                !Boolean(errors.addressLineTwo)))
                          : !isSubmitting &&
                            touched.addressLineTwo &&
                            !Boolean(errors.addressLineTwo)
                      }
                      onChange={(e: React.SyntheticEvent) =>
                        change('addressLineTwo', e)
                      }
                    />
                  </InputContainer>
                )}
              </div>
            )}
            <LogisticsContainer>
              <DatePicker
                label="Date"
                id="eventDate"
                name="eventDate"
                autoOk={true}
                value={eventDate || null}
                disableToolbar
                variant="inline"
                onChange={date =>
                  handleSelectChange('eventDate', formatDisplayDate(date))
                }
                format="MM/dd/yyyy"
                minDate={new Date()}
                placeholder="MM/DD/YYYY"
                fullWidth
                error={touched.eventDate && Boolean(errors.eventDate)}
                helperText={
                  errors.eventDate && touched.eventDate && errors.eventDate
                }
                hasSuccess={
                  editing
                    ? !isSubmitting &&
                      ((isEmpty(touched) && isEmpty(errors)) ||
                        (touched.eventDate && !Boolean(errors.eventDate)))
                    : !isSubmitting &&
                      touched.eventDate &&
                      !Boolean(errors.eventDate)
                }
              />
              <Label htmlFor="Time">Time</Label>
              <Row>
                <Col>
                  <ShortInput
                    placeholder="06:00"
                    id="eventTime"
                    name="eventTime"
                    value={eventTime}
                    fullWidth
                    mask={TimeMask}
                    hasError={
                      !isSubmitting &&
                      touched.eventTime &&
                      Boolean(errors.eventTime)
                    }
                    error={touched.eventTime && Boolean(errors.eventTime)}
                    helperText={
                      errors.eventTime && touched.eventTime && errors.eventTime
                    }
                    hasSuccess={
                      editing
                        ? !isSubmitting &&
                          ((isEmpty(touched) && isEmpty(errors)) ||
                            ((touched.eventTime ||
                              !Boolean(touched.eventTime)) &&
                              !Boolean(errors.eventTime)))
                        : !isSubmitting &&
                          touched.eventTime &&
                          !Boolean(errors.eventTime)
                    }
                    onChange={(e: React.SyntheticEvent) =>
                      change('eventTime', e)
                    }
                  />
                </Col>
                <SelectContainer>
                  <Col>
                    <MiniSelect
                      IconComponent={ArrowDropDown}
                      input={
                        <SelectInput
                          id="eventTimeLocale"
                          width={fullScreen ? '88' : '96'}
                          hasError={
                            touched.eventTimeLocale &&
                            Boolean(errors.eventTimeLocale)
                          }
                        />
                      }
                      value={eventTimeLocale}
                      displayEmpty
                      onChange={(e: React.SyntheticEvent) =>
                        change('eventTimeLocale', e)
                      }
                      helperText={
                        errors.eventTimeLocale &&
                        touched.eventTimeLocale &&
                        errors.eventTimeLocale
                      }
                      error={
                        touched.eventTimeLocale &&
                        Boolean(errors.eventTimeLocale)
                      }
                      hasSuccess={
                        editing
                          ? !isSubmitting &&
                            ((isEmpty(touched) && isEmpty(errors)) ||
                              ((touched.eventTimeLocale ||
                                !Boolean(touched.eventTimeLocale)) &&
                                !Boolean(errors.eventTimeLocale)))
                          : !isSubmitting &&
                            touched.eventTimeLocale &&
                            !Boolean(errors.eventTimeLocale)
                      }
                      inputProps={{
                        name: 'eventTimeLocale',
                        id: 'eventTimeLocale',
                        MenuProps: MenuProps,
                      }}
                    >
                      >
                      {['PM', 'AM'].map(locale => (
                        <MenuItem
                          button={true}
                          component="li"
                          key={locale}
                          value={locale}
                        >
                          <OptionLabel>{locale}</OptionLabel>
                        </MenuItem>
                      ))}
                    </MiniSelect>
                  </Col>
                  <Col>
                    <MiniSelect
                      IconComponent={ArrowDropDown}
                      input={
                        <SelectInput
                          id="timeZone"
                          width={fullScreen ? '88' : '96'}
                          hasError={
                            touched.timeZone && Boolean(errors.timeZone)
                          }
                        />
                      }
                      value={timeZone}
                      displayEmpty
                      onChange={(e: React.SyntheticEvent) =>
                        change('timeZone', e)
                      }
                      helperText={
                        errors.timeZone && touched.timeZone && errors.timeZone
                      }
                      error={touched.timeZone && Boolean(errors.timeZone)}
                      hasSuccess={
                        editing
                          ? !isSubmitting &&
                            ((isEmpty(touched) && isEmpty(errors)) ||
                              ((touched.timeZone ||
                                !Boolean(touched.timeZone)) &&
                                !Boolean(errors.timeZone)))
                          : !isSubmitting &&
                            touched.timeZone &&
                            !Boolean(errors.timeZone)
                      }
                      inputProps={{
                        name: 'timeZone',
                        id: 'timeZone',
                        MenuProps: MenuProps,
                      }}
                    >
                      >
                      {timeZones.map(zone => (
                        <MenuItem
                          button={true}
                          component="li"
                          key={zone.key}
                          value={zone.value}
                        >
                          <OptionLabel>{zone.key}</OptionLabel>
                        </MenuItem>
                      ))}
                    </MiniSelect>
                  </Col>
                </SelectContainer>
              </Row>
            </LogisticsContainer>
            <Grid container alignItems="center">
              <FormControlLabel
                label={
                  <StyledTypography
                    disabled={false}
                    fontSize={14}
                    lineHeight={21}
                  >
                    Show event’s recent supporters
                  </StyledTypography>
                }
                disabled={false}
                control={
                  <CheckBoxIcon
                    editing={false}
                    checked={showSupporters === 'true'}
                    onChange={(e: React.SyntheticEvent) =>
                      toggleCheckbox('showSupporters', e)
                    }
                    value="showSupporters"
                  />
                }
              />
              <MuiToolTip
                title={
                  "Your event page will show which guests are supporting the event's fundraiser through their purchases. You may select to hide this module if you do not want this information visible."
                }
                arrow
                placement="top"
                enterDelay={500}
                leaveDelay={200}
                style={{ fontSize: fullScreen ? 18 : 20 }}
              />
            </Grid>
            {editing && <EditImage event={event} />}
          </div>
        )}
        {editing ? (
          <SaveButtons
            cancelButtonText="CANCEL"
            saveButtonText="SAVE"
            handleCancel={onCancel}
            handleSave={mutationLoading ? () => {} : handleSubmit}
            disableSave={isEmpty(touched)}
          />
        ) : (
          <FooterButtons
            prevStepButtonText="CANCEL"
            nextStepButtonText="NEXT"
            handleNextStepChange={handleSubmit}
            handlePrevStepChange={handlePreviousStepChange}
            disableNext={disableNext}
          />
        )}
      </div>
    </form>
  );
}
